import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  setNewPassword
} from '../actions';
import {
  getSetPwdStatus,
  getSetPwdError
} from '../reducer';
import './user-management.scss';
import GBIButton from '../../util/buttons/components/GBIButton';
import { Link } from 'react-router-dom';
import validateSetPassword from '../util/validateSetPassword';

const mapStateToProps = state => ({
  status: getSetPwdStatus(state),
  apiError: getSetPwdError(state)
});

const mapDispatchToProps = dispatch => ({
  save: (authCode, id, password) => {
    dispatch(setNewPassword(authCode, id, password));
  }
});

class SetPassword extends Component {
  constructor (props) {
    super(props);
    this.state = {
      password: '',
      passwordConf: '',
      errors: {},
      passwordUsed: false,
      passwordConfUsed: false
    };
    this.passwordRef = React.createRef();
    this.passwordConfRef = React.createRef();
  }
  validate () {
    const { password, passwordConf } = this.state;
    const registration = validateSetPassword({
      password, passwordConf
    });
    if (registration.errors) {
      this.setState({ errors: registration.errors });
    } else {
      this.setState({ errors: {} });
    }
  }
  handlePasswordChange () {
    const password = this.passwordRef.current.value;
    this.setState({ password, passwordUsed: true }, this.validate);
  }
  handlePasswordConfChange () {
    const passwordConf = this.passwordConfRef.current.value;
    this.setState({ passwordConf, passwordConfUsed: true }, this.validate);
  }
  hasErrors () {
    return this.state.errors && Object.keys(this.state.errors).length > 0;
  }
  save () {
    if (this.hasErrors()) return;
    const authCode = this.props.match.params.authCode;
    const id = this.props.match.params.id;
    this.props.save(authCode, id, this.state.password);
  }
  renderPassword () {
    let wrapperClass = 'input-wrapper';
    let error = null;
    if (this.state.errors && this.state.errors.password &&
    this.state.passwordUsed) {
      wrapperClass += ' error';
      error = (
        <div className="error-feedback">
          { this.state.errors.password }
        </div>
      );
    }
    return (
      <Fragment>
        <label htmlFor="name">Password</label>
        <div className={ wrapperClass }>
          <input ref={ this.passwordRef }
            type="password"
            autoComplete="new-password"
            onFocus={ () => this.setState({ passwordFocus: true }) }
            onBlur={ () => this.setState({ passwordFocus: false }) }
            onChange={ this.handlePasswordChange.bind(this) } />
          { error }
        </div>
      </Fragment>
    );
  }
  renderPasswordConf () {
    let wrapperClass = 'input-wrapper';
    let error = null;
    if (this.state.errors && this.state.errors.passwordConf &&
    this.state.passwordConfUsed) {
      wrapperClass += ' error';
      error = (
        <div className="error-feedback">
          { this.state.errors.passwordConf }
        </div>
      );
    }
    return (
      <Fragment>
        <label htmlFor="name">Confirm Password</label>
        <div className={ wrapperClass }>
          <input ref={ this.passwordConfRef }
            type="password"
            autoComplete="new-password"
            onChange={ this.handlePasswordConfChange.bind(this) } />
          { error }
        </div>
      </Fragment>
    );
  }
  renderButton () {
    let registerButton = null;
    if (!this.state.errors || Object.keys(this.state.errors).length < 1) {
      const className = this.props.status === 'REGISTERING' ? 'disabled' : '';
      registerButton = (
        <GBIButton label="confirm"
          className={ className }
          onClick={ this.save.bind(this) }/>
      );
    }
    return (
      <div className="button-row">
        <Link className="login-link" to="/login">login</Link>
        { registerButton }
      </div>
    );
  }
  render () {
    return (
      <div id="user-management">
        <h1 className="title">Set your new Password</h1>
        <div className="inputs">
          { this.renderPassword() }
          { this.renderPasswordConf() }
          { this.renderButton() }
        </div>
      </div>
    );
  }
}

SetPassword.propTypes = {
  save: PropTypes.func.isRequired,
  status: PropTypes.string,
  apiError: PropTypes.string,
  clear: PropTypes.func,

  confirm: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      authCode: PropTypes.string,
      id: PropTypes.string
    })
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);
