import getPasswordError from './getPasswordError';

const validateSetPassword = setPwdRequest => {
  const updatedSetPwdRequest = JSON.parse(JSON.stringify(setPwdRequest));
  const errors = {};
  if (getPasswordError(setPwdRequest.password)) {
    errors.password = getPasswordError(setPwdRequest.password);
  }
  if (setPwdRequest.password !== setPwdRequest.passwordConf) {
    errors.passwordConf = 'passwords don\'t match';
  }
  updatedSetPwdRequest.valid = Object.values(errors).length < 1;
  if (updatedSetPwdRequest.valid !== true) {
    updatedSetPwdRequest.errors = errors;
  }
  return updatedSetPwdRequest;
};

export default validateSetPassword;
